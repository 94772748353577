<template>
  <div class="header-container">
    <div class="header" ref="header">
      <div class="hd-container">
        <div class="hd-left">
          <h1>
            <a href="#"><img src="~@/assets/img/logo_wy.png" alt=""></a>
          </h1>
          <div class="current-weather">
            <ul>
              <li class="user-city">{{userCity}}</li>
              <li class="temp">{{currentWeather.temp}}</li>
              <li class="weather-desc">{{currentWeather.description}}</li>
              <li class="decorate-line">|</li>
            </ul>
          </div>
        </div>
        <div class="hd-right">
          <ul class="nav">
            <li>
              <router-link to="/home">首页</router-link>
            </li>
            <li>
              <router-link to="/antithunder">防雷科技</router-link>
              <span class="decorate-dot"></span>
            </li>
            <li>
              <router-link to="/infooperation">信息化运维</router-link>
              <span class="decorate-dot"></span>
            </li>
            <li ref="wdmeteo" @click="showWdmeteoDropdownNav" @mouseenter="showWdmeteoDropdownNav()" @mouseleave="hideWdmeteoDropdownNav()">
            <!-- <li ref="wdmeteo"> -->
              <router-link to="/wisdommeteo">
                智慧气象
              <span class="dowm-arrow" :class="{uparrow: isUpArrow}" ref="navarrow"></span>
              </router-link>
              <span class="decorate-dot"></span>
            </li>
            <li>
              <router-link to="/news">新闻咨询</router-link>
              <span class="decorate-dot"></span>
            </li>
            <li>
              <router-link to="/aboutus">关于我们</router-link>
              <span class="decorate-dot"></span>
            </li>
          </ul>
          <div class="wdmeteo-dropdown-nav" ref="dropdownNav" @mousemove="showWdmeteoDropdownNav(), rotateNavArrow()" @mouseleave="hideWdmeteoDropdownNav()">
          <!-- <div class="wdmeteo-dropdown-nav" ref="dropdownNav"> -->
            <ul>
              <router-link :to="item.routerLink" v-for="(item, index) in wdmeteoDropdownNav" :key="index"  @click.native="dropdownNavClick">
                <li>{{item.navName}}</li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      userCity: '北京',
      currentWeather: {
        temp: "28℃",
        description: "晴"
      },
      // navInfo: ["首页", "防雷科技", "信息化运维", "智慧气象", "新闻咨询", "关于我们"]
      wdmeteoDropdownNav: [
        {
          "routerLink": "/wisdommeteo/weatherdatavisualization",
          "navName": "天气数据可视化"
        },
        {
          "routerLink": "/wisdommeteo/openplatform",
          "navName": "开放平台"
        },
        {
          "routerLink": "/wisdommeteo/rainbowchalk",
          "navName": "彩虹粉笔"
        },
        {
          "routerLink": "/wisdommeteo/thunderstreaksafety",
          "navName": "雷电安全"
        },
        {
          "routerLink": "/wisdommeteo/weatherinsight",
          "navName": "天气洞察"
        }
      ],
      isUpArrow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.calculateHeaderScrollLeft);

    // 判断当前路由决定是否关联智慧气象样式
  },
  methods: {
    showWdmeteoDropdownNav() {
      this.$refs.dropdownNav.style.display = "block";
      this.$refs.wdmeteo.firstElementChild.style.color = "#ffffff";
    },
    hideWdmeteoDropdownNav() {
      this.$refs.dropdownNav.style.display = "none";
      this.$refs.wdmeteo.firstElementChild.style.color = "#d7d7d7";
      // 恢复箭头方向 向下
      this.isUpArrow = false;
    },
    dropdownNavClick() {
      this.$refs.wdmeteo.firstElementChild.addClass = 'router-link-wisdommeteo-active'
    },
    rotateNavArrow() {
      // 选中下拉导航 保持箭头向上
      this.isUpArrow = true;
    },
    // 使header跟随浏览器左右滚动 解决固定定位不跟随滚动bug
    calculateHeaderScrollLeft() {
      let scrollLeft = Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
      this.$refs.header.style.left = -scrollLeft + 'px';
    }
  },
  unmounted() {
    // 销毁监听
    document.removeEventListener('scroll', this.calculateHeaderScrollLeft)
  },
  watch: {
  }
}
</script>

<style lang="scss">
  // 引入字体图标
  @font-face {
    font-family: 'icomoon';
    src:  url('~@/assets/fonts/icomoon.eot?fpcb1m');
    src:  url('~@/assets/fonts/icomoon.eot?fpcb1m#iefix') format('embedded-opentype'),
      url('~@/assets/fonts/icomoon.ttf?fpcb1m') format('truetype'),
      url('~@/assets/fonts/icomoon.woff?fpcb1m') format('woff'),
      url('~@/assets/fonts/icomoon.svg?fpcb1m#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .header-container {
    .header {
      box-sizing: border-box;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      min-width: 1920px;
      height: 60px;
      background-image: url('~@assets/img/header_bg.png');
      z-index: 999;
      .hd-container {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 1206px;
        height: 60px;
        margin: 0 auto;
        .hd-left {
          position: relative;
          float: left;
          width: 380px;
          height: 60px;
          line-height: 60px;
          box-sizing: border-box;
          justify-content: space-between;
          h1 {
            float: left;
            height: 60px;
            margin-right: 35px;
            a {
              display: block;
              height: 100%;
              color: #fff;
              img {
                width: 210px;
                height: 60px;
              }
            }
          }
          .current-weather {
            float: left;
            width: 135px;
            font-size: 18px;
            box-sizing: border-box;
            ul {
              position: relative;
              text-align: right;
              line-height: 60px;
              li {
                float: left;
                color: #fff;
                &:first-child {
                  margin-right: 15px;
                }
                &:nth-of-type(2) {
                  margin-right: 20px;
                }
              }
              .decorate-line {
                position: absolute;
                top: -1px;
                right: 49px;
              }
            }
          }
        }
        .hd-right {
          position: relative;
          margin-left: 380px;
          width: 826px;
          height: 60px;
          font-size: 18px;
          .nav {
            width: 826px;
            height: 60px;
            line-height: 60px;
            padding-left: 90px;
            li {
              position: relative;
              float: left;
              margin-left: 40px;
              cursor: pointer;
              a {
                height: 50px;
                padding: 10px 10px 16px;
                color: #d7d7d7;
                .dowm-arrow::after {
                  position: absolute;
                  top: 0;
                  right: 7px;
                  font-family: 'icomoon';
                  content: '\e900';
                  font-size: 22px;
                  color: #fff;
                  opacity: .9;
                  transition: .3s;
                }
                .uparrow::after {
                  transform: rotate(180deg);
                  transition: .3s;
                }
              }
              a:hover {
                color: #fff;
                // font-weight: 700;
                .dowm-arrow::after {
                  transform: rotate(180deg);
                  transition: .3s;
                }
              }
              &:nth-of-type(4) {
                a {
                  padding-right: 30px;
                }
                .decorate-dot {
                  left: -23px;
                }
              }
              .router-link-exact-active,
              .router-link-wisdommeteo-active,
              .router-link-active {
                color: #25ecff!important;
                font-weight: 700!important;
                border-bottom: 3px solid #25ecff;
                .dowm-arrow::after {
                  color: #25ecff;
                }
              }
              .decorate-dot {
                position: absolute;
                top: 30px;
                left: -21px;
                float: left;
                display: block;
                width: 4px;
                height: 4px;
                background-color: #fff;
                border-radius: 8px;
              }
              
            }
          }
          .wdmeteo-dropdown-nav {
            position: absolute;
            top: 60px;
            right: 228px;
            width: 150px;
            height: 205px;
            padding-top: 15px;
            background-color: #283b54;
            border-radius: 5px;
            display: none;
            z-index: 999;
            ul {
              li {
                width: 122px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                margin: 0 auto 7px;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
              }
              li:hover {
                background-color: #385376;
                border-radius: 5px; 
              }
              .router-link-exact-active {
                li {
                  color: #25ecff!important;
                  font-weight: 700!important;
                }
              }
            }
          }
        }
      }
    }
  }
 
</style>