<template>
  <section class="comprehensive-presentation">
    <div class="cp-wrap">
      <ul class="cp-item-wrap">
        <li v-for="(item, index) in presentationInfo" :key="index" class="cp-item">
          <div class="cp-item-icon"><img src="" alt=""></div>
          <div class="cp-item-content">
            <div class="cp-item-title">{{item.title}}</div>
            <p class="cp-item-decriptions">{{item.presentationWords}}</p>
            <button class="cp-item-btn">
              <a href="#">了解更多</a>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ComprehensivePresentation',
  data() {
    return {
      presentationInfo: [
        {
          'imgUrl': '',
          'title': '技术',
          'presentationWords': '了解我们的睿图的模式预报技、数据同化、数据建模等技术。',
          'refName': 'cpitem1'
        },
        {
          'imgUrl': '',
          'title': '解决方案',
          'presentationWords': '与行业深度结合，为各行业提供准确全面的气象服务，降低天气风险，增加客户收益。',
          'refName': 'cpitem2'
        },
        {
          'imgUrl': '',
          'title': '产品',
          'presentationWords': '提供准确、长时效、多时空分辨率气象预报产品、气象服务产品，满足个人和企业的需求。',
          'refName': 'cpitem3'
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .comprehensive-presentation {
    width: 100%;
    height: 635px;
    padding-top: 160px;
    margin-bottom: 180px;
    .cp-wrap {
      width: 1060px;
      height: 502px;
      margin: 0 auto;
      .cp-item-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        .cp-item {
          float: left;
          width: 336px;
          height: 476px;
          border-radius: 5px;
          box-shadow: 0 2px 6px 0 rgba(255, 255, 255, .1);
          overflow: hidden;
          transition: .3s;
          &:nth-child(2) {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
          }
          &:last-child {
            float: right;
          }
          .cp-item-icon {
            width: 100%;
            height: 220px;
            background-color: #6073a1;
          }
          .cp-item-content {
            position: relative;
            width: 100%;
            height: 256px;
            background-color: #16263d;
            padding-top: 15px;
            padding-left: 26px;
            .cp-item-title {
              position: relative;
              font-family: 'AdobeHeitiStd-Regular';
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 3px;
              &::after {
                position: absolute;
                top: 43px;
                left: 0;
                content: '';
                width: 26px;
                height: 3px;
                background-color: #fff;
                transition-duration: 0.15s;
              }
            }
            .cp-item-decriptions {
              margin-top: 35px;
              color: #d7d7d7;
              line-height: 28px;
            }
            .cp-item-btn {
              position: absolute;
              bottom: 20px;
              left: 26px;
              width: 180px;
              height: 40px;
              background-color: transparent;
              border: 2px solid #41d4d4;
              a {
                font-size: 16px;
                letter-spacing: 4px;
                color: #00ffff;
              }
            }
          }
          &:hover {
            transform: translate(0, -10px);
            transition: .3s;
            &:nth-child(2) {
              position: absolute;
              top: -10px;
              left: 50%;
              transform: translate(-50%, 0);
            }
            .cp-item-content {
              background-color: #1f3c65;
            }
            .cp-item-title {
              &::after {
                width: 80px;
                transition-duration: 0.15s;
              }
            }
            .cp-item-btn {
              background-color: #41d4d4;
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
</style>