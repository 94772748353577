<template>
  <section class="classic-case">
    <div class="classic-case-title">
      经典案例
      <div class="english-title">CLASSIC CASE</div>
    </div>
    <div class="classic-case-swiper">
      <swiper ref="mySwiper" :options="swiperOptions" class="cc-swiper-wrap"  v-if="caseInfo.length">
        <swiper-slide v-for="(item, index) in caseInfo" :key="index" class="cc-item">
          <div class="cc-item-top"></div>
          <div class="cc-item-icon-wrap">
            <img :src="item.iconUrl" alt="">
          </div>
          <div class="cc-item-bottom">
            <div class="cc-item-title">{{item.caseTitle}}</div>
            <p class="cc-item-content">{{item.caseContent}}</p>
            <button class="cc-item-btn"><a :href="item.link">了解更多</a></button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// 引入swiper
import Vue from 'vue'
import 'swiper/swiper-bundle.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


export default {
  name: 'ClassicCase',
  data() {
    return {
      swiperOptions: {
        // observer: true, //修改swiper自己或子元素时，自动初始化swiper 
        // observeParents: true, //修改swiper的父元素时，自动初始化swiper 
        slidesPerView: 8.6,//显示个数
        // loop: true,
        loopedSlides: 8.6,
        // loopAdditionalSlides: 7,
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumRatio: 2
      },
      caseInfo: [
        {
          iconUrl: require('@assets/img/classiccase/icon_yidonghulian.png'),
          caseTitle: '移动互联',
          caseContent: '开展校园气象科普课程，构建系统气象科普模式，通过气象科普活动、研学实践活动等将学',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_zhinengjiashi.png'),
          caseTitle: '智能驾驶',
          caseContent: '气象信息在公路旅行的安全性和流动性方面起着重要作用。准确的天气预报和实时情报对于',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_caihongfenbi.png'),
          caseTitle: '彩虹粉笔',
          caseContent: '开展校园气象科普课程，构建系统气象科普模式，通过气象科普活动、研学实践活动等将学',
          link: '/wisdommeteo/rainbowchalk'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_fangleikeji.png'),
          caseTitle: '防雷科技',
          caseContent: '严格执行国家防雷技术规范和标准，开展建筑物常规检测、新建建筑物防雷装置跟踪辑竣工',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_jiankangyiliao.png'),
          caseTitle: '健康医疗',
          caseContent: '基于数值模式、地理信息、流行病学模型等多种技术，构建综合健康模型，进行健康影响预',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_shuangtandabiao.png'),
          caseTitle: '双碳达标',
          caseContent: '为新能源发电企业风电、光电进行新能源电力评估和高频率短时风光监测预报（高频功率预',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_zhihuinongye.png'),
          caseTitle: '智慧农业',
          caseContent: '为农业生产者和农业生产企业准确快速地提供本地化的农业情报和预报信息，为农业运营决',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/classiccase/icon_tongxinyunwei.png'),
          caseTitle: '通信运维',
          caseContent: '开展校园气象科普课程，构建系统气象科普模式，通过气象科普活动、研学实践活动等将学',
          link: '#'
        }
      ]
    }
  },
  components: {
  },
  computed: {
   swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  updated() {
    // 防止loop出现空白
    if (this.caseInfo.length > 1) {
      this.swiper.init()
    }
  },
  mounted() {
      this.swiper.slideTo(3, 1000, false)
  }
}
</script>

<style lang="scss">
  .swiper-container {
    width: 100%;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      width: 256px!important;
      height: 372px;
      border-radius: 10px;
      margin: 0 15px;
      overflow: hidden;
      cursor: pointer;
      -webkit-box-pack: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
  
</style>

<style lang="scss" scoped>
  .classic-case {
    width: 100%;
    height: 920px;
    padding-top: 175px;
    .classic-case-title {
      position: relative;
      width: 564px;
      height: 47px;
      line-height: 47px;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 3px;
      margin: 0 auto 130px;
      .english-title {
        height: 25px;
        line-height: 25px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .classic-case-swiper {
      .cc-swiper-wrap {
        .cc-item {
          .cc-item-top {
            width: 100%;
            height: 124px;
            background-color: #232f3e;
          }
          .cc-item-icon-wrap {
            position: absolute;
            top: 82px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 73px;
            height: 73px;
            border-radius: 50%;
            background-color: #444e5b;
            border: 5px solid #232f3e;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 48px;
              height: 45px;
            }
          }
          .cc-item-bottom {
            height: 248px;
            padding: 35px 20px 30px;
            background-color: #2f4158;
            .cc-item-title {
              font-size: 24px;
              letter-spacing: 5px;
              font-family: 'AdobeHeitiStd-Regular';
            }
            .cc-item-content {
              font-size: 14px;
              font-family: 'SimHei';
              letter-spacing: 2px;
              line-height: 26px;
              text-indent: 35px;
              text-align: left;
              overflow: hidden;
              // 多行文本溢出
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin-top: 5px;
            }
            .cc-item-btn {
              width: 200px;
              height: 38px;
              line-height: 34px;
              background-color: transparent;
              border: 2px solid #00ffff;
              margin-top: 20px;
              a {
                color: #00ffff;
                font-size: 16px;
                font-family: 'SimHei';
                letter-spacing: 5px;
              }
            }
          }
          &:hover {
            .cc-item-top {
              background-color: #2c3541;
            }
            .cc-item-icon-wrap {
              background-color: #4b535d;
              border: 5px solid #2c3541;
            }
            .cc-item-bottom {
              background-color: #565d67;
              .cc-item-btn {
                background-color: #41d4d4;
                border: none;
                a {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
</style>