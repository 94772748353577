import axios from 'axios'

// const MOCK_URI = '@/mock/data' // 本地测试数据地址
const GAODE_URL = 'https://restapi.amap.com/v5/ip?' // 高德 IP定位url

export function request(config) {
  const instance = axios.create({
    baseURL: GAODE_URL,
    timeout: 5000
  })

  // 请求拦截器
  instance.interceptors.request.use(config => {
    return config
  }, err => {
    console.log(err);
  })

  // 响应拦截器 对返回结果做统一处理
  instance.interceptors.response.use(res => {
    return res
  }, err => {
    console.log(err);
  })


  // 发送请求并返回接收的数据
  return instance(config)
}