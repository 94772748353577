<template>
  <footer class="footer-wrap">
    <div class="footer-top">
      <div class="footer-title">联系我们</div>
      <div class="footer-nav-wrap"> 
        <dl v-for="(item, index) in wyFooterInfo" :key="index" class="footer-nav">
          <dt>{{item.title}}</dt>
          <dd v-for="(listItem, listIndex) in item.list" :key="listIndex">
            <img :src="listItem.icon" alt="" v-if="listItem.icon !== false">
            <a :href="listItem.link">{{listItem.content}}</a>
          </dd>
        </dl>
        <div class="wy-wechat-qrcode">
          <div>万云气象公众号</div>
          <img src="@assets/img/footer/wy_qrcode.png" alt="">
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <span>万云科技</span>
      <span>北京万云科技开发有限公司</span>
      <span>京ICP备2021025068号</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      wyFooterInfo: [
        {
          'title': '产品',
          'list': [
            {
              'icon': false,
              'content': '开放平台',
              'link': '#'
            },
            {
              'icon': false,
              'content': '分析平台',
              'link': '#'
            },
            {
              'icon': false,
              'content': '解决方案',
              'link': '#'
            }
          ]
        },
        {
          'title': '公司',
          'list': [
            {
              'icon': false,
              'content': '关于我们',
              'link': '/aboutus'
            },
            {
              'icon': false,
              'content': '加入我们',
              'link': '#'
            },
            {
              'icon': false,
              'content': '合作伙伴',
              'link': '#'
            },
            {
              'icon': false,
              'content': '新闻资讯',
              'link': '#'
            }
          ]
        },
        {
          'title': '联系方式',
          'list': [
            {
              'icon': require("@assets/img/footer/icon_email.png"),
              'content': '13901319711@139.com',
              'link': 'javascript:void(0);' // 禁止a标签跳转
            },
            {
              'icon': require("@assets/img/footer/icon_adress.png"),
              'content': '北京市海淀区紫竹院路44号',
              'link': 'javascript:void(0);'
            },
            {
              'icon': require("@assets/img/footer/icon_tel.png"),
              'content': '010-68400512',
              'link': 'javascript:void(0);'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-wrap {
    width: 1206px;
    height: 455px;
    margin: 0 auto;
    text-align: left;
    .footer-top {
      width: 100%;
      height: 380px;
      background: url("~@assets/img/footer/footer_bg.png") no-repeat;
      padding-top: 40px;
      .footer-title {
        width: 564px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 3px;
        margin: 0 auto 40px;
      }
      .footer-nav-wrap {
        width: 970px;
        height: 240px;
        margin: 0 210px 0 140px;
        .footer-nav {
          float: left;
          margin-right: 10px;
          &:nth-of-type(2) {
            margin-right: 30px;
          }
          &:nth-of-type(3) {
            margin-right: 80px;
          }
          dt {
            position: relative;
            font-weight: 700;
            font-size: 20px;
            color: #fffefe;
            margin-bottom: 50px;
            &::after {
              position: absolute;
              top: 40px;
              left: 0;
              content: '';
              width: 25px;
              height: 3px;
              background-color: #fff;
            }
          }
          dd {
            white-space: nowrap;
            width: 200px;
            img {
              display: block;
              width: 23px;
              float: left;
              margin-right: 10px;
            }
            a {
              // float: left;
              display: block;
              font-size: 14px;
              color: #fff;
              margin-bottom: 15px;
              &:hover {
                font-weight: 700;
              }
            }
          }
        }
        .wy-wechat-qrcode {
          div {
            font-size: 18px;
          }
          img {
            display: block;
            width: 98px;
            height: 98px;
            margin-top: 25px;
          }
        }
      }
    }
    .footer-bottom {
      margin-top: 30px;
      text-align: center;
      span {
        padding-right: 52px;
        letter-spacing: 2px;
        font-size: 12px;
        color: #d1d1d1;
        &:nth-of-type(-n+2) {
          position: relative;
          &::after {
            position: absolute;
            content: "|";
            width: 2px;
            top: 0;
            right: 25px;
          }
        }
      }
    }
  }
</style>