<template>
  <section class="show-weather-data" v-if="false">
    <div class="user-location">
      <span class="location-icon"><img src="~@assets/img/footer/icon_adress.png" alt=""></span>
      <span>{{userCity[0]}}-{{userCity[1]}}</span>
      <span>{{userLocation}}</span>
    </div>
    <div class="local-weather">
      <div class="today-temp">
        <div class="release-time">今天7:45发布</div>
        <img src="~@assets/img/weatherdata/qing.png" alt="" class="weather-icon">
        <div class="weather-descrip">
          <span>多云转晴</span>
          <span>30℃</span>
        </div>
      </div>
      <div class="detail-data">
        <div class="data-wrap">
          <ul class="data">
            <li>
              {{todayExtremeTemp[0]}} | {{todayExtremeTemp[1]}}
            </li>
            <li v-for="(item, index) in todayMeteoData" :key="index">{{item}}</li>
          </ul>
        </div>
        <div class="title-wrap">
          <ul class="title">
            <li v-for="(item, index) in localWeatherTitle" :key="index" >{{item}}</li>
          </ul>
        </div>
      </div>
      <div class="forecast">
        <div class="forecast-bar">
          <span class="forecast-title">24小时预报</span>
          <span class="more-link">
            <a href="">更多</a>
          </span>
        </div>
        <div class="forecast-graph">
          <div class="line-chart" ref="lineChart"></div>

          <div class="wind-level">
            <ul>
              <li v-for="(windLevel, index) in forecastWindLevel" :key="index">{{windLevel}}级</li>
            </ul>
          </div>
          <div class="wind-direction">
            <ul>
              <li v-for="(windDirection, index) in forecastWindDirection" :key="index">{{windDirection}}</li>
            </ul>
          </div>
          <div class="forecast-time">
            <ul>
              <li v-for="(time, index) in forecastTime" :key="index">{{time}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts/core';
export default {
  name: "ShowWeatherData",
  data() {
    return {
      userCity: ['北京', '海淀'],
      userLocation: '紫竹园路',
      localWeatherTitle: ['最高|最低温度',  '体感温度', '空气湿度', '空气质量'],
      todayExtremeTemp: ['24℃', '15℃'],
      todayMeteoData: ['26℃', '9%', '重度'],
      forecastTempData: [18, 20, 24, 21, 18, 15, 12, 7, 5, 5, 6, 10],  // [24, 26, 29, 28, 28, 26, 20, 19, 18, 16, 18, 21],
      forecastWeatherPhenomena: [7, 7, 9, 9, 9, 9, 9, 9, 7, 7, 17, 18],
      forecastWeatherPhenomenaIcon: [],
      forecastWindDirection: ['东南风', '南风', '东南风', '东南风', '东南风', '东南风', '南风', '南风', '南风', '南风', '东南风', '东南风',],
      forecastWindLevel: [3, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 2],
      forecastTime: ['10am', '12pm', '2pm', '4pm', '6pm', '8pm', '10pm', '0am', '2am', '4am', '6am', '8am'],
      minForecastData: null,
      maxForecastData: null
    }
  },
  mounted() {
    this.getExtremeForecastData(this.forecastTempData);
    console.log(this.minForecastData);
    console.log(this.maxForecastData);
    console.log(this.forecastTempData);

    this.initEcharts();

    this.getForecastWthPhenoIcon(this.forecastWeatherPhenomena)
  },
  methods: {
    // 求气温极值 配置折线图
    getExtremeForecastData(arr) {
      this.minForecastData = Math.min.apply(this, arr);
      this.maxForecastData = Math.max.apply(this, arr);
    },
    // 初始化图表，设置配置项
    initEcharts() {
      var myChart = echarts.init(this.$refs.lineChart);
      myChart.setOption({
        backgroundColor: 'transparent',
        xAxis: {
          data: this.forecastTime,
          show: false
        },
        yAxis: {
          type: 'value',
          show: false,
          max: this.maxForecastData, // 最高温
          min: this.minForecastData, // 最低温
          splitNumber: 2
        },
        series: [
          {
            name: 'temp',
            type: 'line',
            symbol: 'circle', // 拐点类型 实心圆
            symbolSize: 7, // 拐点大小
            animation: true, // hover 拐点默认缩放
            lineStyle: { // 折线样式
              normal: {
                  width: 2, 
                  color: '#ffffff'
              }
            },
            itemStyle: { // 拐点样式
              normal: {
                color:  '#20eefb', 
                borderColor: '#20eefb',
                label: { // 拐点数据相关设置
                  show: true, 
                  textStyle: {
                    fontSize: 10,
                    color: '#ffffff',
                    letterSpace: 0
                  },
                  formatter: function(params) {
                    return (params.value)+"℃"
                  }
                }
              },
              emphasis: { // hover状态 拐点样式
                // color: '#aaa', 
                // borderColor: '#aaa'
              }
            },
            data: this.forecastTempData
          }
        ],
        grid: {
          x: 0, // 图表离canvas 左侧y轴的距离
          y: 0, // 图表离canvas 上侧x轴的距离
          // x2: 0, // 图表离canvas 右侧y轴的距离
          // y2: 0, // 图表离canvas 下侧x轴的距离
          borderWidth: 0,
          width: 515,
          height: 36,
          left: -8, // 图表在canvas的位置
          top: 22
        }
      });
    },
    // 白天黑夜 匹配天气现象图标
    getForecastWthPhenoIcon(arr) {
      let daytime = ['6am', '8am', '10am', '12pm', '2pm', '4pm'];
      // let night = ['6pm', '8pm', '10pm', '12pm', '2am', '4am'];
      arr.forEach((item, index) => {
        switch (item) {
          case 1: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/baoxue.png"
            break;
          case 2: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/baoyu.png"
            break;
          case 3: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/dabaoxue.png"
            break;
          case 4: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/dabaoyu.png"
            break;
          case 5: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/daxue.png"
            break;
          case 6: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/dayu.png"
            break;
          case 7: 
            if (daytime.includes(this.forecastTime[index])) {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/duoyun.png"
            } else {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yejianduoyun.png"
            }
            break;
          case 8: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/leizhenyu.png"
            break;
          case 9: 
            if (daytime.includes(this.forecastTime[index])) {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/qing.png"
            } else {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yejianqing.png"
            }
            break;
          case 10: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/shachenbao.png"
            break;
          case 11: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/tedabaoxue.png"
            break;
          case 12: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/leizhenyu.png"
            break;
          case 13: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/wu.png"
            break;
          case 14: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/xiaoxue.png"
            break;
          case 15: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/xiaoyu.png"
            break;
          case 16: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yangsha.png"
            break;
          case 17: 
            if (daytime.includes(this.forecastTime[index])) {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/zhenyu.png"
            } else {
              this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yejianzhenyu.png"
            }
            break;
          case 18: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yin.png"
            break;
          case 19: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/yujiaxue.png"
            break;
          case 20: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/zhongxue.png"
            break;
          case 21: 
            this.forecastWeatherPhenomenaIcon[index] = "~@assets/img/weatherdata/zhongyu.png"
            break;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .show-weather-data {
    width: 1200px;
    height: 345px;
    margin: 0 auto;
    padding: 15px 0 20px;
    .user-location {
      width: 258px;
      height: 55px;
      line-height: 55px;
      padding: 0 30px;
      margin-bottom: 13px;
      background-color: #3d517e;
      span {
        display: block;
        float: left;
        &:nth-of-type(-n+2) {
          margin-right: 10px;
        }

        &:nth-of-type(2) {
          font-size: 18px;
          font-weight: 700;
        }

        &:nth-of-type(3) {
          font-size: 16px;
        }
      }
      .location-icon {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .local-weather {
      width: 1200px;
      height: 240px;
      background-color: #3d517e;
      padding: 18px 15px 27px;
      div {
        float: left;
      }
      
      .today-temp {
        width: 170px;
        height: 195px;
        margin-right: 30px;
        text-align: center;
        .release-time {
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
          font-size: 16px;
          color: #c6c6c6;
        }
        .weather-icon {
          display: block;
          width: 88px;
          height: 88px;
          margin: 0 auto;
        }
        .weather-descrip {
          width: 100%;
          text-align: center;
          margin-top: 8px;
          span {
            letter-spacing: 0;
            font-size: 24px;
            font-weight: 700;
            &:nth-of-type(1) {
              margin-right: 10px;
            }
          }
        }
      }
      .detail-data {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 430px;
        margin-right: 15px;
        div {
          width: 100%;
          height: 50%;
          ul {
            li {
              float: left;
              width: 92px;
              margin-right: 3px;
              text-align: center;
              &:nth-of-type(1) {
                width: 115px;
                margin-right: 28px;
              }
            }
          }
        }
        .data-wrap {
          margin-bottom: 25px;
          .data {
            li {
              font-size: 22px;
              font-weight: 700;
              letter-spacing: 0;
              span {
                font-size: 18px;
                font-weight: 700;
              }
              &::before {
                  position: absolute;
                  top: 0;
                  left: -8px;
                  content: '';
                  width: 29px;
                  height: 29px;
                  background-size: 29px 29px;
              }
              &:nth-of-type(2) {
                position: relative;
                &::before {
                  left: -10px;
                  background-image: url("~@assets/img/weatherdata/tiganwendu_icon.png");
                }
              }
              &:nth-of-type(3) {
                position: relative;
                &::before {
                  background-image: url("~@assets/img/weatherdata/kongqishidu_icon.png");
                }
              }
              &:nth-of-type(4) {
                position: relative;
                padding-right: 0;
                &::before {
                  background-image: url("~@assets/img/weatherdata/kongqizhiliang_icon.png");
                }
              }
            }
          }
        }
        .title-wrap {
          .title {
            li {
              font-size: 16px;
              color: #c6c6c6;
              &:nth-of-type(2) {
                margin-left: -14px;
              }
            }
          }
        } 
      }
      .forecast {
        width: 506px;
        height: 205px;
        .forecast-bar {
          width: 100%;
          span {
            font-size: 18px;
            a {
              font-size: 18px;
              color: #c6c6c6;
              transition: .2s;
            }
          }
          .forecast-title {
            float: left;
            color: #c6c6c6;
          }
          .more-link {
            float: right;
            &::after {
              content: '\e902';
              font-family: "icomoon";
              width: 20px;
              height: 20px;
              color: #c6c6c6;
              transition: .2s;
            }
            &:hover {
              a {
                color: #ffffff;
                transition: .2s;
              }
              &::after {
                color: #ffffff;
                transition: .2s;
              }
            }
          }
        }
        .forecast-graph {
          width: 100%;
          height: 168px;
          margin-top: 10px;
          overflow: hidden;
          .line-chart {
            width: 509px;
            height: 62px;
          }
          div {
            ul {
              li {
                float: left;
                width: 30px;
                margin-right: 13px;
                font-size: 10px;
                text-align: center;
                padding-bottom: 5px;
                &:nth-of-type(12) {
                  margin-right: 0;
                }
              }
            }
          }
          .wind-direction {
            ul {
              li {
                width: 39px;
                margin-right: 3px;
              }
            }
          }
          .wind-level {
            width: 100%;
            ul {
              li {
                padding-top: 40px;
                &:nth-of-type(11) {
                  margin-right: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>