<template>
  <section class="business-segments">
    <el-carousel :interval="4000" type="card" height="534px" indicator-position="none" arrow="always">
      <el-carousel-item v-for="(item, index) in bsSegmentsInfo" :key="index">
        <div class="bg-mask"></div>
        <div class="bs-content">
          <div class="segment-icon"><img :src="item.iconSrc" alt=""></div>
          <div class="segment-title">{{item.title}}</div>
          <p class="segment-description">{{item.description}}</p>
        </div>
        <button class="bs-seemore-btn"><a :href="item.link">了解更多</a></button>
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>
  export default {
    name: "BusinessSegments",
    data() {
      return {
        bsSegmentsInfo: [
          {
            iconSrc: require('@assets/img/businesssegment/bs_icon1.png'),
            title: "彩虹粉笔",
            description: "“科学玩家”气象科学stem课程，致力于“玩转科学，乐享其中”的理念。丰富多彩的气象科普活动、研学实践活动，将科学知识和实践探究有机结合。",
            link: '/wisdommeteo/rainbowchalk'
          },
          {
            iconSrc: require('@assets/img/businesssegment/bs_icon2.png'),
            title: "开放平台",
            description: "提供精细化天气数据接口，满足不同业务的大部分需求。",
            link: "#"
          },
          {
            iconSrc: require('@assets/img/businesssegment/bs_icon3.png'),
            title: "防雷科技",
            description: "严格执行国家防雷技术规范和标准，开展建筑物常规检测、新建建筑物防雷装置跟踪辑竣工检测、电子信息系统防雷检测等的防雷服务保障工作。",
            link: "/antithunder"
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .business-segments {
    .el-carousel--horizontal {
      overflow: visible;
      padding-left: 110px;
      .el-carousel__container {
        .el-carousel__arrow {
          background-color: transparent;
          border: 2px solid rgba(255, 255, 255, .5);
          &:hover {
            border: 2px solid rgba(255, 255, 255, 1);
            .el-icon-arrow-left:before {
              font-weight: 700;
            }
            .el-icon-arrow-right:before {
              font-weight: 700;
            }
          }
        }
        .el-carousel__arrow--left {
          position: absolute;
          top: 50%;
          left: -260px;
          width: 80px;
          height: 80px;
          .el-icon-arrow-left:before {
            font-size: 28px;
            font-weight: 400;
          }
        }
        .el-carousel__arrow--right {
          position: absolute;
          top: 50%;
          right: -150px;
          width: 80px;
          height: 80px;
          .el-icon-arrow-right:before {
            font-size: 28px;
            font-weight: 400;
          }
        }
        .el-carousel__item  {
          width: 383px;
          text-align: center;
          padding: 77px 27px 0px;
          &:nth-child(2n) {
            background-color: #99a9bf;
          }
          
          &:nth-child(2n+1) {
            background-color: #d3dce6;
          }
          .el-carousel__mask {
            background-color: #12298e;
            opacity: .9;
            z-index: -9;
          }
        }
        .el-carousel__item--card {
          &:nth-of-type(1) {
            background: url('~@assets/img/businesssegment/bs_bg1.png') no-repeat;
          }
          &:nth-of-type(2) {
            background: url('~@assets/img/businesssegment/bs_bg2.png');
          }
          &:nth-of-type(3) {
            background: url('~@assets/img/businesssegment/bs_bg3.png');
          }
        }
        .is-active {
          .bs-content {
            background-color: rgba(255, 255, 255, .45);
            .segment-title {
              font-weight: 700;
              color: #1837ba;
            }
            .segment-description {
              color: #1837ba;
            }
          }
          .bs-seemore-btn {
            background-color: #41d4d4;
            border: none;
            a {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }
    }
    
  }

  
</style>

<style lang="scss" scoped>
  .business-segments {
    width: 1206px;
    // margin: 200px auto 210px;
    margin: 0 auto 210px;
    padding-top: 200px;
    font-family: 'SimHei';
    .bs-content {
      position: relative;
      width: 330px;
      height: 305px;
      margin-bottom: 60px;
      text-align: center;
      padding: 87px 20px 20px;
      color: #fff;
      .segment-icon {
        position: absolute;
        top: 22px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 56px;
        height: 46px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .segment-title {
        font-size: 24px;
        margin-bottom: 40px;
        letter-spacing: 10px;
      }
      .segment-description {
        text-indent: 40px;
        text-align: left;
        line-height: 30px;
      }
    }
    .bs-seemore-btn {
      z-index: 9999;
      width: 248px;
      height: 50px;
      line-height: 46px;
      background-color: rgba(0, 0, 0, .2);
      border: 3px solid #00ffff;
      a {
        display: block;
        text-align: center;
        color: #00ffff;
        font-size: 16px;
        letter-spacing: 5px;
      }
    }
  }
</style>