import { request } from "./index"

// const mockUri = '/home/userIp.js'

const MY_GAODE_KEY = '0e6cfd33ceba11d6e2bd33d91071966c' // 王铭个人高德账户 key

// 统一管理home page数据请求
// export function getHomeUserIp() {
//   return request({
//     url: mockUri
//   })
// }

export function getHomeUserLocation(type, ip) {
  return request({
    params: {
      key: MY_GAODE_KEY,
      type,
      ip
    }
  })
}

export function getUserDetailLocation(locations, types) {
  return request({
    params: {
      key: MY_GAODE_KEY,
      locations,
      types
    }
  })
}