<template>
  <section class="news-info">
    <div class="news-info-wrap">
      <div class="news-info-title">
        新闻资讯
        <div class="english-title">LATEST NEWS</div>
      </div>
      <div class="arrow-prev arrow" @click="arrowClick('prev')"></div>
      <el-carousel trigger="click" height="404px" arrow="never" indicator-position="none" ref="cardShow">
        <el-carousel-item v-for="(item) in newsInfo" :key="item.id">
          <div class="ni-item-left"><img :src="item.imgSrc" alt=""></div>
          <div class="ni-item-right">
            <div class="ni-item-title">{{item.title}}</div>
            <p class="ni-item-content">{{item.newsContent}}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="arrow-next arrow" @click="arrowClick('next')"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NewsInfo',
  data() {
    return {
      newsInfo: [
        {
          // imgSRC: require(''),
          id: '1',
          title: "新闻资讯1",
          newsContent: "严格执行国家防雷技术规范和标准，开展建筑物常规检测、新建建筑物防雷装置跟踪辑竣工检测、电子信息系统防雷检测等的防雷服务保障工作。"
        },
        {
          // imgSRC: require(''),
          id: '2',
          title: "新闻资讯2",
          newsContent: "严格执行国家防雷技术规范和标准，开展建筑物常规检测、新建建筑物防雷装置跟踪辑竣工检测、电子信息系统防雷检测等的防雷服务保障工作。"
        },
        {
          // imgSRC: require(''),
          id: '3',
          title: "新闻资讯3",
          newsContent: "严格执行国家防雷技术规范和标准，开展建筑物常规检测、新建建筑物防雷装置跟踪辑竣工检测、电子信息系统防雷检测等的防雷服务保障工作。"
        }
      ]
    }
  },
  methods: {
    arrowClick(val) {
      if(val === 'prev') {
        this.$refs.cardShow.prev()
      } else {
        this.$refs.cardShow.next()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep.el-carousel--horizontal {
    // overflow: visible;
    .el-carousel__container {
      .el-carousel__item {
        visibility: hidden;
        // display: none;
      }
      .is-active {
        visibility: visible;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .news-info {
    width: 100%;
    height: 880px;
    padding-top: 145px;
    .news-info-wrap {
      position: relative;
      width: 1206px;
      height: 100%;
      margin: 0 auto;
      .news-info-title {
        position: relative;
        width: 564px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 3px;
        margin: 0 auto 88px;
        .english-title {
          height: 25px;
          line-height: 25px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .arrow {
        position: absolute;
        top: 50%;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, .5);
        border-radius: 50%;
        transition: .3s;
        &:hover {
          border: 2px solid rgba(255, 255, 255, 1);
          &::before {
            font-weight: 700;
          }
        }
      }
      .arrow-prev {
        left: -150px;
        &::before {
          font-family: element-icons!important;
          content: '\e6de';
          font-size: 28px;
        }
      }
      .arrow-next {
        right: -150px;
        &::before {
          font-family: element-icons!important;
          content: "\e6e0";
          font-size: 28px;
        }
      }
      .ni-item-left {
        float: left;
        width: 550px;
        height: 402px;
        background-color: #aaa;
      }
      .ni-item-right {
        float: left;
        padding-left: 50px;
        padding-top: 80px;
        width: 656px;
        height: 402px;
        .ni-item-title {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 5px;
          margin-bottom: 66px;
        }
        .ni-item-content {
          font-family: 'SimHei';
          font-size: 18px;
          line-height: 50px;
          letter-spacing: 2px;
          text-indent: 35px;
          color: #d7d7d7;
        }
      }
    }
    
  }
</style>