<template>
  <div class="banner">
    <slot>
      <el-carousel trigger="click" height="800px">
        <el-carousel-item v-for="(item, index) in bannerInfo" :key="index">
          <!-- <img :src="item.imgUri"> -->
          <div class="banner-bg" :style="{backgroundImage: 'url(' + item.imgUri + ')'}"></div>
          <button class="try-on" v-if="item.buttonFlag">
            <a href="#">{{item.buttonLeft}}</a>
          </button>
          <button class="seemore-btn" v-if="item.buttonFlag">
            <a href="#">{{item.buttonRight}}</a>
          </button>
        </el-carousel-item>
      </el-carousel>
    </slot>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {}
  },
  props: {
    bannerInfo: {
      type: Array,
      default: () => {
        return [
          {imgUri: require("@assets/img/banner/banner1.png"), buttonFlag: true, buttonLeft: '试用', buttonRight: '了解更多'},
          {imgUri: require("@assets/img/banner/banner1.png"), buttonFlag: true, buttonLeft: '试用', buttonRight: '了解更多'},
          {imgUri: require("@assets/img/banner/banner1.png"), buttonFlag: true, buttonLeft: '试用', buttonRight: '了解更多'},
          {imgUri: require("@assets/img/banner/banner1.png"), buttonFlag: true, buttonLeft: '试用', buttonRight: '了解更多'}
        ]
      }
    }
  },
  mounted() {

  }
}
</script>
<style lang="scss">
  .el-carousel {
    position: relative;
    .el-carousel__arrow {
      width: 80px;
      height: 80px;
      background-color: rgba(31,45,61, .3);
    }
    .el-icon-arrow-left:before {
      font-size: 28px;
    }
    .el-icon-arrow-right:before {
      font-size: 28px;
    }
    .el-carousel__indicators--horizontal {
      left: 1425px;
      bottom: 90px;
      .el-carousel__indicator--horizontal {
        padding: 12px 6px;
      }
      .el-carousel__button {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
      .el-carousel__indicator.is-active {
        button {
          width: 20px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .banner {
    width: 100%;
    height: 800px;
    ::v-deep .el-carousel__item {
      background-color: #101721;
      .banner-bg {
        width: 100%;
        height: 800px;
        max-width: 1920px;
        margin: 0 auto;
        background-size: 1920px auto;
        background-position: center;
      }
    }

    .try-on,
    .seemore-btn {
      position: absolute;
      bottom: 227px;
      width: 180px;
      height: 48px;
      z-index: 99;
      border-style: none;
      background-image: linear-gradient(to right, #101721, #101721);
      border: 2px solid #00ffff;
      a {
        font-family: 'SimHei';
        font-size: 16px;
        color: #00ffff;
        letter-spacing: 5px;
      }
    }
    .try-on {
      left: 360px;
    }

    .seemore-btn {
      left: 640px;
    }
    .try-on,
    .seemore-btn {
      &:hover {
        background-image: linear-gradient(to right, #4db1f1, #1dd9e5);
        border: none;
        // background-color: #101721;
        a {
          color: #fff;
        }
      }
    }
  }
</style>