import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
// 懒加载导入组件
const Antithunder = () => import('@views/antithunder/Antithunder.vue')
const Infooperation = () => import('@views/infooperation/Infooperation.vue')
const News = () => import('@views/news/News.vue')
const Aboutus = () => import('@views/aboutus/Aboutus.vue')
const WisdomMeteo = () => import('@views/wisdommeteo/WisdomMeteo.vue')
const WeatherDataVisualization = () => import('@views/wisdommeteo/weatherDataVisualization/WeatherDataVisualization.vue')
const OpenPlatform = () => import('@views/wisdommeteo/openPlatform/OpenPlatform.vue')
const RainbowChalk = () => import('@views/wisdommeteo/rainbowChalk/RainbowChalk.vue')
const ThunderstreakSafety = () => import('@views/wisdommeteo/thunderstreakSafety/ThunderstreakSafety.vue')
const WeatherInsight = () => import('@views/wisdommeteo/weatherInsight/WeatherInsight.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home' // 重定向 首次打开显示
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/antithunder',
    name: 'Antithunder',
    component: Antithunder
  },
  {
    path: '/infooperation',
    name: 'Infooperation',
    component: Infooperation
  },
  {
    path: '/wisdommeteo',
    name: 'Wisdommeteo',
    component: WisdomMeteo,
    children: [
      {
        path: '',
        redirect: 'weatherdatavisualization'
      },
      {
        path: 'weatherdatavisualization',
        component: WeatherDataVisualization
      },
      {
        path: 'openplatform',
        component: OpenPlatform
      },
      {
        path: 'rainbowchalk',
        component: RainbowChalk
      },
      {
        path: 'thunderstreaksafety',
        component: ThunderstreakSafety
      },
      {
        path: 'weatherinsight',
        component: WeatherInsight
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: Aboutus
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
