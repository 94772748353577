<template>
  <div id="app">
    <Header></Header>
  </div>
</template>

<script type="text/javascript">
  import Header from "@components/common/Header.vue"

  // 引入数据请求方法
  import { getHomeUserLocation, getUserDetailLocation } from '@/network/home.js'

  export default {
    name: "App",
    components: {
      Header
    },
    data() {
      return {
        userCoordinate: null,
      }
    },
    created() {
      this.getUserLocation(4, '106.120.123.213');
      this.getUserDetailLocation(this.userCoordinate, 190107)
    },
    mounted() {
    },
    methods: {
      // 封装数据请求及处理方法
      getUserLocation(type, ip) {
        getHomeUserLocation(type, ip).then(res => {
          console.log(res);
          this.userCoordinate = res.data.location;
        })
      },
      // 1、待解决  异步函数
      getUserDetailLocation(locations, types) {
        console.log(locations);
        getUserDetailLocation(locations, types).then(res => {
          console.log(res);
        })
      }
    }
  }
</script>


<style lang="scss">
@import "~@/assets/css/base.scss";

#app {
  font-family: 'Microsoft YaHei', 'Avenir', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  padding-top: 60px;
}

</style>
