<template>
  <section class="co-partner">
    <div class="co-partner-wrap">
      <div class="co-partner-title">
        合作伙伴
        <div class="english-title">PARTNER</div>
      </div>
      <div class="co-partner-logo">
        <div class="cp-logo-item-wrap cp-logo-top">
          <ul>
            <li v-for="(item, index) in coPartnerLogoTop" :key="index" class="cp-logo-item">
              <img :src="item.logoSrc" alt="">
            </li>
          </ul>
        </div>
        <div class="cp-logo-item-wrap cp-logo-bottom">
          <ul>
            <li v-for="(item, index) in coPartnerLogoBottom" :key="index" class="cp-logo-item">
              <img :src="item.logoSrc" alt="">
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CoPartner',
  data() {
    return {
      coPartnerLogoTop: [
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo1.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo2.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo3.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo4.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo5.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo6.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo1.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo2.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo3.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo4.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo5.png')
        }
      ],
      coPartnerLogoBottom: [
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo7.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo8.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo9.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo10.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo11.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo12.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo7.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo8.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo9.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo10.png')
        },
        {
          logoSrc: require('@assets/img/copartner/co-partner_logo11.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .co-partner {
    width: 100%;
    height: 470px;
    padding-top: 150px;
    margin-bottom: 160px;
    overflow: hidden;
    .co-partner-wrap {
      width: 1206px;
      height: 325px;
      margin: 0 auto;
      .co-partner-title {
        position: relative;
        width: 564px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 3px;
        margin: 0 auto 85px;
        .english-title {
          height: 25px;
          line-height: 25px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .co-partner-logo {
        position: relative;
        width: 100%;
        height: 180px;
        overflow: hidden;
        .cp-logo-item-wrap {
          width: 100%;
          height: 120px;
          ul {
            width: 10000px;
            .cp-logo-item {
              float: left;
              height: 120px;
              line-height: 120px;
              margin-left: 20px;
              img {
                display: block;
                height: 70px;
              }
            }
          }
          
        }
        .cp-logo-top {
          position: absolute;
          top: 0;
          left: 0;
          animation: topLogoScroll 18s 1s linear infinite normal;
        }
        .cp-logo-bottom {
          position: absolute;
          top: 120px;
          left: 0;
          animation: bottomLogoScroll 18s 3s linear infinite normal;
        }
      }
    }
  }

  @keyframes topLogoScroll {
    100% {
      // 根据6个li元素总宽度计算得出
      left: -1480.84px;
    }
  }

  @keyframes bottomLogoScroll {
    100% {
      left: -1403.6px;
    }
  }
</style>