<template>
  <div class="home">
    <banner class="banner"></banner>
    <div class="content-wrap">
      <show-weather-data></show-weather-data>
      <business-segments></business-segments>
      <comprehensive-presentation></comprehensive-presentation>
      <classic-case></classic-case>
      <news-info></news-info>
      <co-partner></co-partner>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// 组件
import Banner from '@components/content/Banner.vue'
import ShowWeatherData from './chidComps/ShowWeatherData.vue'
import BusinessSegments from './chidComps/BusinessSegments.vue'
import ComprehensivePresentation from './chidComps/ComprehensivePresentation.vue'
import ClassicCase from './chidComps/ClassicCase.vue'
import NewsInfo from './chidComps/NewsInfo.vue'
import CoPartner from './chidComps/CoPartner.vue'
import Footer from '@components/common/Footer.vue'

export default {
  name: 'Home',
  components: {
    Banner, 
    ShowWeatherData,
    BusinessSegments,
    ComprehensivePresentation,
    ClassicCase,
    NewsInfo,
    CoPartner,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;
    background-color: #2e436f;
    .banner {
      overflow: hidden;
    }
    .content-wrap {
      width: 100%;
      background-image: url('~@assets/img/home_background_img1.png');
    }
  }
</style>